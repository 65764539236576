import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUserCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../config';
import { format } from 'date-fns';
import StateMunicipalityDropdown from './StateMunicipalityDropdown';
import Swal from 'sweetalert2';

const AddPlatePopup = ({ isOpen, onRequestClose, loadPlateRenewalData }) => {
  const [municipalities, setMunicipalities] = useState([]);
  const [plateTypeOptions, setPlateTypeOptions] = useState([]);
  const [formPlateNo, setFormPlateNo] = useState('');
  const [selectedMunicipality, setSelectedMunicipality] = useState(null);
  const [formPlateType, setFormPlateType] = useState(null);
  const [formExpiration, setFormExpiration] = useState(null);
  const [errors, setErrors] = useState({});


  useEffect(() => {
    // Fetch municipalities from the API
    fetch(API_URL + '/municipalities')
      .then((response) => response.json())
      .then((data) => {
        setMunicipalities(data);
      })
      .catch((error) => {
        console.error('Error fetching municipalities:', error);
      });
  }, []);

  const handleStateChange = (selectedOption) => {
    setSelectedMunicipality(selectedOption);
    setFormPlateType(null);
    setPlateTypeOptions([]);
    setFormExpiration(null);

    if (selectedOption) {
      fetchPlateTypes(selectedOption.value);
    }
  };

  const fetchPlateTypes = (municipalityId) => {
    fetch(API_URL + `/plate-types?state_id=${municipalityId}`)
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((plateType) => ({
          value: plateType.id,
          label: plateType.name,
        }));
        setPlateTypeOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching plate types:', error);
      });
  };

  const validateForm = () => {
    const validationErrors = {};

    if (!formPlateNo) {
      validationErrors.plateNo = 'Please enter the plate number.';
    }

    if (!selectedMunicipality) {
      validationErrors.municipality = 'Please select a county.';
    }

    // if (!formPlateType) {
    //   validationErrors.plateType = 'Please select the plate type.';
    // }

    if (!formExpiration) {
      validationErrors.expiration = 'Please select the expiration date.';
    }

    setErrors(validationErrors); // Update the errors state with validation errors

    return validationErrors;
  };

  const handleAddPlate = () => {

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please fix the form errors.',
        });
        return;
    }

    const authToken = localStorage.getItem('token');

    if (!authToken) {
        Swal.fire({
          icon: 'warning',
          title: 'Unauthorized',
          text: 'Please login to continue.',
        });
        // Handle the case where the token is missing
        return;
    }

    // Create a plate renewal object based on the form data
    const newPlateRenewal = {
        plate_number: formPlateNo,
        municipality_id: selectedMunicipality.value,
        plate_type_id: formPlateType.value,    
        expiration_date: formExpiration.toISOString(), // Ensure the date is in ISO format
    };

    // Make a POST request to the API to save the plate renewal data
    fetch(API_URL + '/plate-renewals', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPlateRenewal),
    })
    .then(async (response) => {
        if (response.status === 201) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Plate renewal data saved successfully',
            });

            /* resetting form */
            setFormPlateNo('');
            setFormPlateType(null);
            setFormExpiration(null);
            onRequestClose();
            loadPlateRenewalData();
        } else if (response.status === 422) {
            // Validation error response from the API
            const errorData = await response.json();
            const errorMessages = Object.values(errorData.errors).flat();
            
            Swal.fire({
              icon: 'error',
              title: 'Validation Errors',
              text: errorMessages.join(', '),
            });
        } else {
            // Handle other response statuses as needed
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to save plate renewal data',
            });
        }
    })
    .catch((error) => {
        // Handle API request error
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save plate renewal data',
        });
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="login-popup"
      overlayClassName="overlay"
    >
      <div className="login-content add-plate-pop">
        <button className="close-button" onClick={onRequestClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>Add Plate</h2>

        <div className="form-group">
          
          <StateMunicipalityDropdown
            data={municipalities}
            selectedMunicipality={selectedMunicipality}
            setSelectedMunicipality={handleStateChange}
          />

          {errors.municipality && <div className="error-message">{errors.municipality}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="plateNoInput">License Plate Number</label>
          <input
            type="text"
            className="form-control"
            id="plateNoInput"
            placeholder="ABC123"
            value={formPlateNo}
            onChange={(e) => setFormPlateNo((e.target.value) ? e.target.value.replace(/[^0-9a-zA-Z]+/ig, "") : '')}
          />
          {errors.plateNo && <div className="error-message">{errors.plateNo}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="expirationInput">Expiration</label>
          <DatePicker
            id="expirationInput"
            className="form-control"
            selected={formExpiration}
            onKeyDown={(e) => { e.preventDefault() }}
            onChange={(date) => setFormExpiration(new Date(date.getFullYear(), date.getMonth() + 1, 0, 0))}
            placeholderText="Select expiration date"
            showMonthYearPicker
            dateFormat="MMM yyyy"
            minDate={new Date(new Date().setDate(new Date().getDate() - 45))}
          />
          {errors.expiration && <div className="error-message">{errors.expiration}</div>}
        </div>

        <button className="btn btn-primary login-button" onClick={handleAddPlate}>
          Add <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </Modal>
  );
};

export default AddPlatePopup;
